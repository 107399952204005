

















































































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  setup(_, { root }) {
    const model = reactive({
      isActive: false,
      endDate: "" as any,
      endEditDate: "" as any,
      languageVersion: "polish",
      limitType: "char",
      maxLimit: 2200,
      polishInfo: "",
      englishInfo: "",
      longSummaryType: "char",
      longSummaryLimitType: null,
      longSummaryLimit: 2200 as number | null,
      consents: [] as any,
      ratingToReject: 0,
      ratingToAccept: 0,
      autoAccept: false,
    });

    const state = reactive({
      dialog: false,
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      configExists: false,
      isValid: false,
      longSummaryType: "text",
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return "Endpointu nie znaleziono";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const getSettings = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/abstract-config`)
        .then(({ data }) => {
          state.configExists = true;
          model.isActive = data.isActive;
          model.endDate = data.endDate
            ? new Date(data.endDate).toISOString().split("T")[0]
            : undefined;
          model.endEditDate = data.endEditDate
            ? new Date(data.endEditDate).toISOString().split("T")[0]
            : undefined;
          model.languageVersion = data.languageVersion || undefined;
          model.limitType = data.limitType || undefined;
          model.maxLimit = data.maxLimit || undefined;
          model.polishInfo = data.polishInfo || undefined;
          model.englishInfo = data.englishInfo || undefined;
          state.longSummaryType = ["char", "word"].includes(
            data.longSummaryType
          )
            ? "text"
            : data.longSummaryType;
          model.longSummaryLimitType = data.longSummaryType || undefined;
          model.longSummaryLimit = data.longSummaryLimit || undefined;
          model.consents = data.consents.map((el: any) => ({
            id: el.id || undefined,
            text: el.text,
            isRequired: el.isRequired,
          }));
          model.ratingToAccept = data.ratingToAccept * 100;
          model.ratingToReject = data.ratingToReject * 100;
          model.autoAccept = data.autoAccept || false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.configExists = false;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(getSettings);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      const data = {
        isActive: model.isActive,
        endDate: model.endDate || null,
        endEditDate: model.endEditDate || null,
        languageVersion: model.languageVersion || null,
        limitType: model.limitType || null,
        maxLimit: model.maxLimit || null,
        polishInfo: model.polishInfo || null,
        englishInfo: model.englishInfo || null,
        longSummaryType:
          state.longSummaryType === "text"
            ? model.longSummaryLimitType
            : state.longSummaryType,
        longSummaryLimit:
          state.longSummaryType === "text" ? model.longSummaryLimit : null,
        consents: model.consents,
        ratingToAccept: model.ratingToAccept / 100,
        ratingToReject: model.ratingToReject / 100,
        autoAccept: model.autoAccept,
      };

      if (state.configExists) {
        axiosInstance
          .put(`/event/${root.$route.params.id}/abstract-config`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$t("event.panel.abstract.config.success.edit"),
            });
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        axiosInstance
          .post(`/event/${root.$route.params.id}/abstract-config`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$t("event.panel.abstract.config.success.add"),
            });
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    const addItem = () => {
      model.consents.push({
        id: undefined,
        text: null,
        isRequired: false,
      });
    };

    const removeItem = (index: number) => {
      model.consents.splice(index, 1);
    };

    watch(
      () => model.longSummaryType,
      () => {
        if (model.longSummaryType !== "text") {
          model.longSummaryLimit = null;
        }
      }
    );

    const isDisabled = (number: number) => {
      if (number && number > 0) {
        return true;
      } else return false;
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { model, state, onSubmit, addItem, removeItem, isDisabled, rules };
  },
});
